import {Component, Input, OnInit} from '@angular/core';
import {IonicModule, ModalController, NavController, ToastButton} from "@ionic/angular";
import {RouterLink} from "@angular/router";
import {constants} from "../../../shared/constants/variables";
import {MenuItemForListDto} from "../../../_dtos/menu-items/menu-items-for-list-dto";
import {MenuItemDetailsComponent} from "../../menu-items/menu-item-details/menu-item-details.component";
import {AlertService} from "../../../services/alert.service";

@Component({
    selector: 'app-hero-feature-menu-item',
    templateUrl: './hero-feature-menu-item.component.html',
    standalone: true,
    imports: [IonicModule, RouterLink]
})
export class HeroFeatureMenuItemComponent implements OnInit {
    @Input() imageName: string;
    @Input() alt: string;
    @Input() title: string;
    @Input() subtitle: string;
    @Input() description: string;
    @Input() menuItemId: string;

    constructor(
        private _modal: ModalController,
        private _alert: AlertService,
        private _nav: NavController
    ) {
    }

    get src() {
        return `${constants.primaryPhotoFolder}/${this.imageName}`;
    }

    ngOnInit() {
    }

    async onMenuItemClick() {
        const modal = await this._modal.create({
            component: MenuItemDetailsComponent,
            componentProps: {menuItemId: +this.menuItemId}
        });
        await modal.present();
        const {data, role} = await modal.onWillDismiss();
        if (role === 'confirm') {
            const toastButtons: ToastButton[] = [
                {text: 'View Cart', handler: () => this._nav.navigateForward('/cart')},
                {text: 'View Menu', handler: () => this._nav.navigateForward('/menu')},
            ];
            await this._alert.success('Cart quantity updated', toastButtons);
        }
    }
}
