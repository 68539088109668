import {CanActivateFn} from '@angular/router';
import {AuthService} from "@auth0/auth0-angular";
import {inject} from "@angular/core";
import {firstValueFrom} from "rxjs";
import {AlertService} from "../services/alert.service";

export const adminRoleGuardGuard: CanActivateFn = async (route, state) => {
    const authService = inject(AuthService);
    const alertService = inject(AlertService);

    const user = await firstValueFrom(authService.user$);
    if (!user) {
        alertService.warn('Please login to continue');
        return false;
    }

    const role = user['lido-delivery-2-api/roles'];
    if (!role || !Array.isArray(role)) {
        alertService.warn('You do not have the necessary permissions to access this page');
        return false;
    }

    return role.includes('lido-delivery-admin') || role.includes('superuser');
};
